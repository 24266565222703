import { observable, action, computed, makeObservable } from 'mobx'
import getAppVersion from 'helpers/getAppVersion'

class ViewStore {
  notification = {}

  maintenance = false

  online = true

  isDesktop = false

  callbackLink = '/'

  modalVisible = false

  modalData = {}

  prevModals = []

  filtersVisible = false

  currentAppVersion = getAppVersion()

  latestAppVersion = ''

  timeout = null

  darkMode = false

  systemDarkMode = false

  scrollPosition = {}

  get isNewerVersionAvailable() {
    const latest = this.latestAppVersion
    const current = this.currentAppVersion

    // If isn't localhost and versions.length is longer than 5 and versions aren't equal, there's new version
    if (
      !process.env.GATSBY_IS_LOCAL &&
      latest &&
      current &&
      latest.length > 5 &&
      current.length > 5 &&
      latest !== current
    ) {
      return true
    }
    return false
  }

  constructor() {
    makeObservable(this, {
      notification: observable,
      maintenance: observable,
      online: observable,
      isDesktop: observable,
      callbackLink: observable,
      modalVisible: observable,
      modalData: observable,
      filtersVisible: observable,
      currentAppVersion: observable,
      latestAppVersion: observable,
      darkMode: observable,
      systemDarkMode: observable,
      scrollPosition: observable,
      isNewerVersionAvailable: computed,
      setCallbackLink: action,
      notify: action,
      removeOldNotification: action,
      setDesktop: action,
      setMaintenance: action,
      setOnlineStatus: action,
      showModal: action,
      pushModal: action,
      popModal: action,
      setScrollPosition: action,
    })

    //
    this.setInitialConnectionStatus()
  }

  setInitialConnectionStatus() {
    if (typeof navigator !== 'undefined') {
      this.setOnlineStatus(navigator.onLine)
    }
  }

  setCallbackLink(path) {
    this.callbackLink = path || '/'
  }

  notify(type, message) {
    this.notification = { type, message }
    clearTimeout(this.timeout)
    const time = type === 'success' ? 1300 : 3500
    this.timeout = setTimeout(() => this.removeOldNotification(), time)
  }

  removeOldNotification() {
    this.notification = {}
  }

  setDesktop(isDesktop) {
    this.isDesktop = isDesktop
  }

  setMaintenance(value) {
    this.maintenance = !!value
  }

  setOnlineStatus(online) {
    this.online = online
  }

  showModal(config) {
    this.prevModals = []
    this.modalData = config
    this.modalVisible = true
  }

  pushModal(config) {
    this.prevModals.push(this.modalData)
    this.modalData = config
    this.modalVisible = true
  }

  popModal() {
    this.modalData = this.prevModals.pop()
    if (!this.modalData) this.modalVisible = false
  }

  setScrollPosition(view, value) {
    this.scrollPosition[view] = value
  }

  hideModal = action(() => {
    this.prevModals = []
    this.modalVisible = false
    this.modalData = {}
  })

  toggleFilters = action(() => {
    this.filtersVisible = !this.filtersVisible
  })

  hideFilters = action(() => {
    this.filtersVisible = false
  })

  setAppVersion = action((version) => {
    this.latestAppVersion = version
  })

  setSystemDarkMode = action((isDark) => {
    this.systemDarkMode = isDark
    if (!localStorage.getItem('darkMode')) {
      console.log('setSystemDarkMode', isDark)
      this.darkMode = isDark
    }
  })

  setDarkMode = action((isDark) => {
    this.darkMode = isDark
    localStorage.setItem('darkMode', isDark)
  })

  resetDarkMode = action(() => {
    this.darkMode = this.systemDarkMode
    localStorage.removeItem('darkMode')
  })
}

export default new ViewStore()
