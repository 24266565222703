/* eslint max-len: ["error", { "code": 120 }] */
import 'regenerator-runtime/runtime'
import moment from 'moment-timezone'

import fetcher from 'helpers/fetcher'
import viewStore from 'stores/viewStore'

const apiUrl = process.env.GATSBY_API

export const routes = {
  // AUTHENTICATION
  GET_OTP: `${apiUrl}/sendOTP`,
  SIGN_IN: `${apiUrl}/login`,
  LOGOUT: `${apiUrl}/logout`,

  // CHAT
  CHAT_LOGIN: `${apiUrl}/chat/login`,
  // CHAT_SEND_MESSAGE: `${apiUrl}/chat/message`, // Unused for now

  // PUSH NOTIFICATIONS
  SET_PUSH_TOKEN: `${apiUrl}/push/token`,
  SEND_PUSH: `${apiUrl}/push/send`,

  // PROFILE
  GET_PROFILE: `${apiUrl}/profile`,
  UPDATE_PROFILE: `${apiUrl}/profile`, // PATCH
  DELETE_ACCOUNT: `${apiUrl}/profile`, // DELETE
  AGREE_TERMS: `${apiUrl}/profile/agree-terms`, // PATCH
  GET_SETUP_INTENT: `${apiUrl}/profile/card/intent`, // GET
  SAVE_CARD: `${apiUrl}/profile/card`, // POST
  DELETE_CARD: `${apiUrl}/profile/card`, // DELETE
  IS_PHONE_USED: `${apiUrl}/isPhoneUsed`, // GET /isPhoneUsed/:phone
  IS_EMAIL_USED: `${apiUrl}/isEmailUsed`, // GET /isEmailUsed/:phone
  UPLOAD_PROFILE_PHOTO: `${apiUrl}/profile/photo`, // GET /profile/photo

  // DOGS
  GET_DOG: `${apiUrl}/dog`, // GET /dog/:id
  GET_USER_DOGS: `${apiUrl}/dog/user`, // GET /dog/user/:userId
  UPDATE_DOG: `${apiUrl}/dog`, // PATCH /dog/:id
  CREATE_DOG: `${apiUrl}/dog`, // POST (admin)
  DELETE_DOG: `${apiUrl}/dog`, // DELETE /dog/:id (admin)
  UPLOAD_DOG_PHOTO: `${apiUrl}/dog/photo`, // POST /dog/photo/:id
  DELETE_DOG_PHOTO: `${apiUrl}/dog/photo`, // DELETE /dog/photo/:id
  UPLOAD_FILE: `${apiUrl}/file`, // POST /file
  UPLOAD_OWNER_SIGNATURE: `${apiUrl}/dog/ownerSignature`, // POST /dog/ownerSignature/:id
  DELETE_OWNER_SIGNATURE: `${apiUrl}/dog/ownerSignature`, // DELETE /dog/ownerSignature/:id

  // COMPANY
  GET_COMPANY_AVAILABLE_DAYS: `${apiUrl}/company/availableDays`, // POST /company/availableDays
  GET_MY_COMPANY: `${apiUrl}/company/my`, // GET /company/my
  GET_COMPANY: `${apiUrl}/company`, // GET /company/:id
  GET_COMPANY_CONTACT_DETAILS: `${apiUrl}/company/contactDetails`, // GET /company/contactDetails/:id
  GET_COMPANY_POLICIES: `${apiUrl}/company/policies`, // GET /company/policies/:id
  CREATE_COMPANY: `${apiUrl}/company`, // POST /company
  UPDATE_COMPANY: `${apiUrl}/company/my`, // PATCH /company
  UPLOAD_COMPANY_LOGO: `${apiUrl}/company/logo`, // POST /company/logo
  DELETE_COMPANY_LOGO: `${apiUrl}/company/logo`, // DELETE /company/logo
  GET_COMPANY_SETTINGS: `${apiUrl}/company/settings`, // GET /company/setings/:setting
  UPDATE_COMPANY_SETTINGS: `${apiUrl}/company/settings`, // POST /company/setings/:setting
  GET_PAYMENT_METHODS: `${apiUrl}/paymentMethods`, // GET /paymentMethods

  // STRIPE
  GET_STRIPE_ACCOUNT: `${apiUrl}/stripe/account`, // GET
  CREATE_STRIPE_ACCOUNT: `${apiUrl}/stripe/account`, // POST
  UPDATE_STRIPE_ACCOUNT: `${apiUrl}/stripe/account`, // PATCH
  STRIPE_UPLOAD_API: 'https://files.stripe.com/v1/files', // POST
  GET_ACCOUNT_BALANCE: `${apiUrl}/stripe/account/balance`, // GET /stripe/account/balance
  GET_PAYMENT_CAPABILITY: `${apiUrl}/stripe/charges-enabled`, // GET /stripe/charges-enabled
  GET_ONBOARDING_LINK: `${apiUrl}/stripe/onboarding`, // GET /stripe/account/onboarding
  SAVE_COMPANY_CARD: `${apiUrl}/stripe/card`, // POST
  DELETE_COMPANY_CARD: `${apiUrl}/stripe/card`, // DELETE

  // PAYOUTS
  GET_PAYOUT: `${apiUrl}/payout`, // GET
  GET_INCOMING_PAYOUT: `${apiUrl}/payout/incoming`, // GET
  GET_PAYOUTS: `${apiUrl}/payouts`, // GET
  DO_PAYOUT: `${apiUrl}/payout`, // POST

  // BOOKING
  GET_BOOKING_PRICE: `${apiUrl}/booking/calculatePrice`, // POST
  CREATE_PAYMENT_INTENT: `${apiUrl}/booking/paymentIntent`, // POST
  CREATE_BOOKING: `${apiUrl}/booking`, // POST
  CREATE_ADMIN_BOOKING: `${apiUrl}/booking/admin`, // POST

  // WALKS
  GET_WALKS: `${apiUrl}/walks`, // API /walks
  GET_USER_WALKS: `${apiUrl}/walk/user`, // API /walk/user/:userId
  COUNT_BUSINESS_BOOKINGS: `${apiUrl}/walk/countBusinessBookings`, // GET /walk/countBusinessBookings (admin)
  GET_COMPANY_WALKS: `${apiUrl}/walk/company`, // GET /walk/company/ (admin)
  UPDATE_WALKS_ORDER: `${apiUrl}/walk/company/order`, // PATCH /walk/:id
  MARK_BOOKINGS_AS_PAID: `${apiUrl}/walks/payment`, // POST /walks/payment
  GET_WALK: `${apiUrl}/walk`, // API /walk/:walkId
  RESCHEDULE_WALK: `${apiUrl}/walk/reschedule`, // PATCH /walk/reschedule/:id
  ASSIGN_STAFF: `${apiUrl}/walk/assignStaff`, // PATCH /walk/assignStaff/:id
  CANCEL_WALK: `${apiUrl}/walk/cancel`, // POST /walk/cancel/:id
  UPDATE_BOOKING: `${apiUrl}/walk`, // PATCH /walk/:id
  UPDATE_BOOKING_REPORT: `${apiUrl}/walk/report`, // POST /walk/report/:id

  // BOOKING GROUPS
  GET_BOOKINGS_GROUP: `${apiUrl}/bookingsGroup`, // GET :id
  CREATE_BOOKINGS_GROUP: `${apiUrl}/bookingsGroup`, // POST
  UPDATE_BOOKINGS_GROUP: `${apiUrl}/bookingsGroup`, // PATCH
  DELETE_BOOKINGS_GROUP: `${apiUrl}/bookingsGroup`, // DELETE

  // USERS_A (admin)
  GET_USERS: `${apiUrl}/user`,
  CREATE_USER: `${apiUrl}/user`, // POST
  INVITE_CLIENT: `${apiUrl}/user/invite`, // POST /user/invite
  BULK_CLIENT_UPlOAD: `${apiUrl}/clients/bulkCreate`, // POST
  GET_USER: `${apiUrl}/user`, // GET /user/:id
  UPDATE_USER: `${apiUrl}/user`, // PATCH /user/:id
  UPDATE_USER_GROUP: `${apiUrl}/user/group`, // PATCH /user/group/:id
  DELETE_USER: `${apiUrl}/user`, // DELETE /user/:id
  CAN_USER_BE_DELETED: `${apiUrl}/user/canBeDeleted`, // get /user/canBeDeleted:id
  UPLOAD_USER_PHOTO: `${apiUrl}/user/photo`, // POST /user/photo/:id
  UPDATE_CLIENT_DISABLED_SERVICES: `${apiUrl}/user/disabledServices`, // PATCH /user/disabledServices/:id

  // STAFF_A (admin)
  GET_STAFF_LIST: `${apiUrl}/staff`,
  GET_STAFF_LIST_TO_ASSIGN: `${apiUrl}/staffToAssign`,
  CREATE_STAFF_MEMBER: `${apiUrl}/staff`, // POST
  GET_STAFF_MEMBER: `${apiUrl}/staff`, // GET /staff/:id
  UPDATE_STAFF_MEMBER: `${apiUrl}/staff`, // PATCH /staff/:id
  CAN_STAFF_BE_DELETED: `${apiUrl}/staff/canBeDeleted`, // get /staff/canBeDeleted:id
  DELETE_STAFF_MEMBER: `${apiUrl}/staff`, // DELETE /staff/:id

  // SERVICE_A (admin)
  CREATE_SERVICE: `${apiUrl}/service`, // POST
  GET_SERVICES_LIST: `${apiUrl}/services`,
  GET_SERVICE: `${apiUrl}/service`, // GET /service/:id
  UPDATE_SERVICE: `${apiUrl}/service`, // PATCH /service/:id
  UPDATE_SERVICE_PRICING: `${apiUrl}/service/pricing`, // PATCH /service/pricing/:id
  UPDATE_SERVICES_ORDER: `${apiUrl}/service/company/order`, // PATCH /service/company/order
  DELETE_SERVICE: `${apiUrl}/service`, // DELETE /service/:id

  // CUSTOM PRICES
  GET_SERVICE_CUSTOM_PRICES: `${apiUrl}/service/customPrices`, // GET :id
  UPDATE_SERVICE_CUSTOM_PRICES: `${apiUrl}/service/customPrices`, // PATCH :id

  // INVOICES
  GET_CLIENTS_TO_INVOICE: `${apiUrl}/clientsToInvoice`, // GET
  CREATE_INVOICE: `${apiUrl}/invoice`, // POST
  GET_INVOICE: `${apiUrl}/invoice`, // GET /invoice/:id
  GET_INVOICES: `${apiUrl}/invoices`, // GET /invoices
  GET_CLIENT_INVOICES: `${apiUrl}/invoices/client`, // GET /invoices/client/:id
  UPDATE_INVOICE: `${apiUrl}/invoice`, // PATCH /invoice/:id
  DELETE_INVOICE: `${apiUrl}/invoice`, // DELETE /invoice/:id
  DELETE_INVOICE_ITEM: `${apiUrl}/invoiceItem`, // DELETE /invoiceItem/:id

  GET_MY_INVOICES: `${apiUrl}/invoices/my`, // GET /invoices/my

  // SUBSCRIPTION_A
  CREATE_SUBSCRIPTION: `${apiUrl}/subscription`, // POST
  GET_SUBSCRIPTION: `${apiUrl}/subscription`, // GET
  CREATE_CUSTOMER_PORTAL_SESSION: `${apiUrl}/createCustomerPortalSession`, // POST
  OPEN_SUBSCRIPTION_SESSION: `${apiUrl}/openSubscriptionsSession`, // GET

  // CALENDAR
  GET_CALENDAR: `${apiUrl}/calendar`, // GET

  // CLIENTS GROUPS
  GET_GROUPS: `${apiUrl}/clientsGroups`, // GET
  GET_GROUP: `${apiUrl}/clientsGroup`, // GET :id
  CREATE_GROUP: `${apiUrl}/clientsGroup`, // POST
  UPDATE_GROUP: `${apiUrl}/clientsGroup`, // PATCH
  DELETE_GROUP: `${apiUrl}/clientsGroup`, // DELETE

  // UTILS
  DELETE_ME: `${apiUrl}/removeTestAccount`, // POST

  //REPORTS
  GET_REPORTS: `${apiUrl}/reports`,
}

const r = routes

// Prepare request config: add content type, auth token,
// pass headers if any
const prepareConfig = ({ body, method, headers }) => {
  const token = localStorage.getItem('token')

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  }

  if (body) config.body = body
  if (method) config.method = method
  if (headers) config.headers = { ...config.headers, ...headers }

  return config
}

// Append query params (object) to url string
const appendQueryParams = (oldUrl, params) => {
  let url = oldUrl

  // If no params, just return string
  if (!params || !Object.keys(params).length) {
    return url
  }

  Object.keys(params).forEach((key, i) => {
    url += i === 0 ? '?' : '&'
    url += `${key}=${params[key]}`
  })

  return url
}

// Get error message from HTML.
// There's a chance that response from API will be in HTML.
// This function takes message from between <pre> tags and returns string
function getErrorMessageFromHTML(message) {
  if (!message?.startsWith('<!DOCTYPE html>')) {
    return message
  }

  const preStart = message.indexOf('<pre>')
  const preEnd = message.indexOf('</pre>')

  // If <pre></pre> not found - return whole message
  if (preStart === -1 || preEnd === -1) {
    return message
  }

  return message.slice(preStart + 5, preEnd)
}

const fetchData = async (url, config) => {
  try {
    const response = await fetcher(url, config)
    if (response.headers) {
      const appVersion = response.response.headers.get('app_version')
      viewStore.setAppVersion(appVersion || '')
    }
    return response.body
  } catch (e) {
    if (e.status === 503 && e?.body?.maintenance === true) {
      viewStore.setMaintenance(true)
    } else if (e.status === 401 && url !== r.GET_PROFILE) {
      console.log('UNAUTHORISED', url)
      viewStore.notify('warning', e.body?.message || 'Unauthorised')
    } else if (e.status === 422) {
      // TODO: Review this
      viewStore.notify('warning', e.body.message)
      console.log(e.body.message, e.body, url)
      throw new Error(e.body.message)
    } else if (e.status === 429) {
      viewStore.notify('danger', 'Too many requests. Try later.')
      console.log(e.body.message, e.body, url)
      throw new Error(e.body.message)
    } else if (e.status === 500 && url === r.GET_OTP) {
      return e.body
    } else if (e.status === 500) {
      const message = getErrorMessageFromHTML(
        e?.message || e?.body?.message || e?.body || e.statusText,
      )

      viewStore.notify('danger', message)

      // TODO: Review this
      console.log(JSON.stringify(e, null, 3))

      throw new Error(message)
    } else if (e?.message && e.message?.startsWith("Cannot read property 'includes'")) {
      // Do nothing.
    } else if (e.constructor === TypeError) {
      console.log('Network error', e.status, url, e)
      viewStore.notify('warning', 'Server connection error')
      throw new Error('Network error')
    } else if (e.status === 413) {
      throw new Error('File is too large')
    } else {
      console.log('unknown fetchData ERROR', e, url)
      throw new Error(e.message || 'Unknown API error. Unfortunately. :(')
    }
    return e
  }
}

async function simpleRequest({ body, method, params, url: requestUrl }) {
  // Append query params to url if exist
  const url = appendQueryParams(requestUrl, params)

  const config = prepareConfig({
    method: method || 'GET',
    body,
  })
  return fetchData(url, config)
}

function getRequest({ body, params, url }) {
  return simpleRequest({ body, params, method: 'GET', url })
}

function postRequest({ body, params, url }) {
  return simpleRequest({ body, params, method: 'POST', url })
}

function patchRequest({ body, params, url }) {
  return simpleRequest({ body, params, method: 'PATCH', url })
}
function deleteRequest({ body, params, url }) {
  return simpleRequest({ body, params, method: 'DELETE', url })
}

/**
 *
 * AUTHENTICATION
 *
 */

export const getOtpCode = (body) => postRequest({ url: r.GET_OTP, body })
export const isEmailUsed = (email) => getRequest({ url: `${r.IS_EMAIL_USED}/${email}` })
export const isPhoneUsed = (phone) => getRequest({ url: `${r.IS_PHONE_USED}/${phone}` })
export const signIn = (body) => postRequest({ url: r.SIGN_IN, body })
export const logout = (body) => postRequest({ url: r.LOGOUT, body })

/**
 *
 * CHAT
 *
 */

export const chatLogin = () => postRequest({ url: r.CHAT_LOGIN })
// expor const sendChatMessage = (body) => postRequest({ url: r.CHAT_SEND_MESSAGE, body }) // TODO: implement

/**
 *
 * PUSH NOTIFICATIONS
 *
 */

export const setPushToken = (body) => postRequest({ url: r.SET_PUSH_TOKEN, body })
export const sendPushNotification = (body) => postRequest({ url: r.SEND_PUSH, body })

/**
 *
 * PROFILE ACTIONS
 *
 */
export const getProfile = () => getRequest({ url: r.GET_PROFILE })

export const updateProfile = (body) => patchRequest({ url: r.UPDATE_PROFILE, body })

export const deleteProfile = () => deleteRequest({ url: r.DELETE_ACCOUNT })

export const agreeTerms = (body) => patchRequest({ url: r.AGREE_TERMS, body })

export const getSetupIntent = () => getRequest({ url: r.GET_SETUP_INTENT })

export const saveCard = (body) => postRequest({ url: r.SAVE_CARD, body })

export const removeCard = () => deleteRequest({ url: r.DELETE_CARD })

export const uploadProfilePhoto = (photo) =>
  postRequest({
    url: r.UPLOAD_PROFILE_PHOTO,
    body: { photo },
  })

/**
 *
 * DOG ACTIONS
 *
 */

export const getDog = (id) => getRequest({ url: `${r.GET_DOG}/${id}` })
export const getUserDogs = (userId) => getRequest({ url: `${r.GET_USER_DOGS}/${userId}` })
export const updateDog = (id, body) => patchRequest({ url: `${r.UPDATE_DOG}/${id}`, body })
export const deleteDog = (id) => deleteRequest({ url: `${r.DELETE_DOG}/${id}` })

export const createDog = (body) => postRequest({ url: r.CREATE_DOG, body })

export const uploadDogPhoto = (id, photo) =>
  postRequest({ url: `${r.UPLOAD_DOG_PHOTO}/${id}`, body: { photo } })

export const deleteDogPhoto = (id) => deleteRequest({ url: `${r.DELETE_DOG_PHOTO}/${id}` })

export const uploadOwnerSignature = (id, dataURI) =>
  postRequest({
    url: `${r.UPLOAD_OWNER_SIGNATURE}/${id}`,
    body: { dataURI },
  })

export const deleteOwnerSignature = (id) =>
  deleteRequest({ url: `${r.DELETE_OWNER_SIGNATURE}/${id}` })

export const uploadFile = ({ name, folder, file }) =>
  postRequest({
    url: r.UPLOAD_FILE,
    body: { file, folder, name },
  })

/**
 *
 * COMPANY ACTIONS
 *
 */

export const getCompany = (id) => getRequest({ url: `${r.GET_COMPANY}/${id}` })
export const getMyCompany = () => getRequest({ url: r.GET_MY_COMPANY })

export const getAccountBalance = (params = {}) => getRequest({ url: r.GET_ACCOUNT_BALANCE, params })

export const canUseOnlinePayments = () => getRequest({ url: r.GET_PAYMENT_CAPABILITY })
export const getOnboardingLink = () => getRequest({ url: r.GET_ONBOARDING_LINK })

export const getAvailableDays = (serviceId, dogIds, reschedulingFromTs) => {
  return postRequest({
    url: r.GET_COMPANY_AVAILABLE_DAYS,
    body: { serviceId, dogIds, ...(reschedulingFromTs ? { reschedulingFromTs } : undefined) },
  })
}

export const updateCompany = (body) => patchRequest({ url: r.UPDATE_COMPANY, body })

export const uploadCompanyLogo = (logo) =>
  postRequest({ url: r.UPLOAD_COMPANY_LOGO, body: { logo } })

export const deleteCompanyLogo = () => deleteRequest({ url: r.DELETE_COMPANY_LOGO })

/**
 * STRIPE ACTIONS
 */

export const getStripeAccount = () => getRequest({ url: r.GET_STRIPE_ACCOUNT })

export const updateStripeAccount = (body) => patchRequest({ url: r.UPDATE_STRIPE_ACCOUNT, body })

/**
 * PAYOUT ACTIONS
 */

export const getPayouts = (params = {}) => getRequest({ url: r.GET_PAYOUTS, params })

export const getPayout = (id) => getRequest({ url: `${r.GET_PAYOUT}/${id}` })
export const getIncomingPayout = () => getRequest({ url: r.GET_INCOMING_PAYOUT })
export const doPayout = () => postRequest({ url: r.DO_PAYOUT })

export const getCompanySettings = (settingType) =>
  getRequest({ url: `${r.GET_COMPANY_SETTINGS}/${settingType}` })

export const updateCompanySettings = (settingType, body) =>
  postRequest({ url: `${r.UPDATE_COMPANY_SETTINGS}/${settingType}`, body })

export const getPaymentMethods = () => getRequest({ url: r.GET_PAYMENT_METHODS })

/**
 *
 * BOOKING ACTIONS
 *
 */

export const getBookingPrice = (body) => postRequest({ url: r.GET_BOOKING_PRICE, body })
export const createPaymentIntent = (body) => postRequest({ url: r.CREATE_PAYMENT_INTENT, body })
export const createBooking = (body) => postRequest({ url: r.CREATE_BOOKING, body })
export const createBookingWithoutPayment = (body) =>
  postRequest({ url: r.CREATE_ADMIN_BOOKING, body })

/**
 *
 * WALKS ACTIONS
 *
 */

export const getWalks = (params = {}) => getRequest({ url: r.GET_WALKS, params })

export const getUserWalks = (userId, params = {}) =>
  getRequest({ url: `${r.GET_USER_WALKS}/${userId}`, params })

export const rescheduleWalk = (id, body) =>
  patchRequest({ url: `${r.RESCHEDULE_WALK}/${id}`, body })

export const assignStaffToWalk = (id, body) =>
  patchRequest({ url: `${r.ASSIGN_STAFF}/${id}`, body })

export const getWalk = (id) => getRequest({ url: `${r.GET_WALK}/${id}` })
export const cancelWalk = (id, body) => postRequest({ url: `${r.CANCEL_WALK}/${id}`, body })

export const updateBooking = (id, body) => patchRequest({ url: `${r.UPDATE_BOOKING}/${id}`, body })
export const updateBookingReport = (id, body) =>
  postRequest({ url: `${r.UPDATE_BOOKING_REPORT}/${id}`, body })

export const updateWalksOrder = (body) => patchRequest({ url: r.UPDATE_WALKS_ORDER, body })

export const markBookingsAsPaid = (body) => postRequest({ url: r.MARK_BOOKINGS_AS_PAID, body })

/**
 * WALKS ADMIN
 */

export const countBusinessBookings = (params = {}) =>
  getRequest({ url: r.COUNT_BUSINESS_BOOKINGS, params })

export const getCompanyWalks = (params = {}) => getRequest({ url: r.GET_COMPANY_WALKS, params })

/**
 * BOOKINGS GROUPS
 */

export const getBookingsGroup = (id) => getRequest({ url: `${r.GET_BOOKINGS_GROUP}/${id}` })

export const createBookingsGroup = (body) => postRequest({ url: r.CREATE_BOOKINGS_GROUP, body })

export const updateBookingsGroup = (id, body) =>
  patchRequest({ url: `${r.UPDATE_BOOKINGS_GROUP}/${id}`, body })

export const deleteBookingsGroup = (id) =>
  deleteRequest({ url: `${r.DELETE_BOOKINGS_GROUP}/${id}` })

/**
 * USER ACTIONS
 */

export const getUsers = () => getRequest({ url: r.GET_USERS })

export const getUser = (uid) => getRequest({ url: `${r.GET_USER}/${uid}` })

export const createUser = (body) => postRequest({ url: r.CREATE_USER, body })

export const inviteClient = (body) => postRequest({ url: r.INVITE_CLIENT, body })

export const uploadClientBulk = (body) => postRequest({ url: r.BULK_CLIENT_UPlOAD, body })

export const updateUser = (id, body) => patchRequest({ url: `${r.UPDATE_USER}/${id}`, body })

export const updateUserGroup = (id, body) =>
  patchRequest({ url: `${r.UPDATE_USER_GROUP}/${id}`, body })

export const canClientBeDeleted = (id) => getRequest({ url: `${r.CAN_USER_BE_DELETED}/${id}` })

export const deleteUser = (id) => deleteRequest({ url: `${r.DELETE_USER}/${id}` })

export const uploadUserPhoto = (id, photo) =>
  postRequest({ url: `${r.UPLOAD_USER_PHOTO}/${id}`, body: { photo } })

export const updateClientDisabledServices = (id, body) =>
  patchRequest({ url: `${r.UPDATE_CLIENT_DISABLED_SERVICES}/${id}`, body })

/**
 * STAFF ACTIONS (A)
 */

export const getStaffList = () => getRequest({ url: r.GET_STAFF_LIST })

export const getStaffListToAssign = () => getRequest({ url: r.GET_STAFF_LIST_TO_ASSIGN })

export const getStaffMember = (uid) => getRequest({ url: `${r.GET_STAFF_MEMBER}/${uid}` })

export const createStaffMember = (body) => postRequest({ url: r.CREATE_STAFF_MEMBER, body })

export const updateStaffMember = (id, body) =>
  patchRequest({ url: `${r.UPDATE_STAFF_MEMBER}/${id}`, body })

export const canStaffBeDeleted = (id) => getRequest({ url: `${r.CAN_STAFF_BE_DELETED}/${id}` })

export const deleteStaffMember = (id) => deleteRequest({ url: `${r.DELETE_STAFF_MEMBER}/${id}` })

/**
 * SERVICES
 */

export const createService = (body) => postRequest({ url: r.CREATE_SERVICE, body })

export const getServices = () => getRequest({ url: r.GET_SERVICES_LIST })

export const getService = (uid) => getRequest({ url: `${r.GET_SERVICE}/${uid}` })

export const updateService = (id, body) => patchRequest({ url: `${r.UPDATE_SERVICE}/${id}`, body })

export const updateServicesOrder = (body) => patchRequest({ url: r.UPDATE_SERVICES_ORDER, body })

export const deleteService = (id) => deleteRequest({ url: `${r.DELETE_SERVICE}/${id}` })

// UPDATE_SERVICE_PRICING: `${apiUrl}/service/pricing`, // PATCH /service/pricing/:id

/**
 * CUSTOM PRICING
 */

export const getServiceCustomPrices = (id) =>
  getRequest({ url: `${r.GET_SERVICE_CUSTOM_PRICES}/${id}` })

export const updateServiceCustomPrices = (id, body) =>
  patchRequest({ url: `${r.UPDATE_SERVICE_CUSTOM_PRICES}/${id}`, body })

/**
 * INVOICES
 */

export const getClientsToInvoice = (params = {}) =>
  getRequest({ url: r.GET_CLIENTS_TO_INVOICE, params })

export const createInvoice = (body) => postRequest({ url: r.CREATE_INVOICE, body })

export const getInvoices = (params = {}) => getRequest({ url: r.GET_INVOICES, params })

export const getClientInvoices = (clientId) =>
  getRequest({ url: `${r.GET_CLIENT_INVOICES}/${clientId}` })

export const getMyInvoices = () => getRequest({ url: r.GET_MY_INVOICES })

export const getInvoice = (id) => getRequest({ url: `${r.GET_INVOICE}/${id}` })

export const updateInvoice = (id, body) => patchRequest({ url: `${r.UPDATE_INVOICE}/${id}`, body })

export const deleteInvoice = (id) => deleteRequest({ url: `${r.DELETE_INVOICE}/${id}` })

export const deleteInvoiceItem = (id, body) =>
  deleteRequest({ url: `${r.DELETE_INVOICE_ITEM}/${id}`, body })

// SUBSCRIPTIONS

export const createSubscription = (body) => postRequest({ url: r.CREATE_SUBSCRIPTION, body })

export const getMySubscription = (params = {}) => getRequest({ url: r.GET_SUBSCRIPTION, params })

export const createCustomerPortalSession = (body) =>
  postRequest({ url: r.CREATE_CUSTOMER_PORTAL_SESSION, body })

export const openSubscriptionSession = () => getRequest({ url: r.OPEN_SUBSCRIPTION_SESSION })

// CALENDAR

export const getCalendar = (params = {}) => getRequest({ url: r.GET_CALENDAR, params })

// UTILS
export const deleteMe = (phone) => {
  if (process.env.GATSBY_ENV !== 'development') {
    throw Error('You are on production')
  }

  const url = `${r.DELETE_ME}/${phone}?secret=${
    process.env.GATSBY_USER_REMOVE_SECRET
  }${moment().format('DDMMYYYY')}`

  return getRequest({ url })
}

// REPORTS

export const getReports = (body) => postRequest({ url: r.GET_REPORTS, body })

/**
 *
 * GROUPS ACTIONS
 *
 */

export const getClientsGroup = (id) => getRequest({ url: `${r.GET_GROUP}/${id}` })

export const getClientsGroups = () => getRequest({ url: r.GET_GROUPS })

export const createClientsGroup = (body) => postRequest({ url: r.CREATE_GROUP, body })

export const updateClientsGroup = (id, body) =>
  patchRequest({ url: `${r.UPDATE_GROUP}/${id}`, body })

export const deleteClientsGroup = (id) => deleteRequest({ url: `${r.DELETE_GROUP}/${id}` })
